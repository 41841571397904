import styled from 'styled-components';
// import { Link } from 'gatsby';
// import { muiTypography } from '@gannochenko/ui.styled-components';

export const PageLayoutContent = styled.div`
    flex-grow: 1;
    height: 100%;
    position: relative;
`;

// export const PageLayoutBackLink = styled(Link)`
//     text-decoration: none;
//     ${muiTypography('caption')};
// `;
