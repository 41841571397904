import { ABOUT, BLOG_LIST, CONTACTS, SKILLS, HOBBIES_LIST } from './pathTemplates';

export const menu = [
    { text: 'Home', link: '/' },
    { text: 'Blog', link: BLOG_LIST },
    { text: 'Skills', link: SKILLS },
    { text: 'About', link: ABOUT },
    { text: 'Hobbies', link: HOBBIES_LIST },
    { text: 'Contacts', link: CONTACTS },
];
