import React from 'react';
import { SkillBlockPropsType } from '../type';
// import { Skills } from '../../Skills';
import { SkillsV2 } from '../../SkillsV2';

export const useSkillBlock = ({
    children,
    skills,
    flip,
    ...props
}: SkillBlockPropsType) => {
    let left = children;
    let right = <SkillsV2 list={skills || ''} marginTop="1.5rem" />;

    return {
        rootProps: props,
        gridProps: {
            container: true,
            flip,
        },
        left: flip ? right : left,
        right: flip ? left : right,
    };
};
