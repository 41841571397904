import React, { FC } from 'react';
import { ThemeProvider as MUIThemeProvider } from '@material-ui/core/styles';
import { ThemeProvider } from 'styled-components';
import { QueryClient, QueryClientProvider } from 'react-query';
import SimpleReactLightbox from 'simple-react-lightbox';
import { MDXProvider } from '@mdx-js/react';

import { theme, GlobalStyle } from '../../style';
import { StateProviders } from '../../states/providers';
import { NetworkStatusProvider } from '../NetworkStatusProvider';
import {WrappedMDXComponents} from "./MDXComponents";

const queryClient = new QueryClient();

/**
 * This is a top-level wrapper, it wraps everything else, including the ApplicationLayout.
 */
export const Providers: FC = ({ children }) => {
    return (
        <SimpleReactLightbox>
            <MUIThemeProvider theme={theme}>
                <ThemeProvider theme={theme}>
                    <>
                        <GlobalStyle />
                        <MDXProvider components={WrappedMDXComponents}>
                            <QueryClientProvider client={queryClient}>
                                <NetworkStatusProvider>
                                    <StateProviders>{children}</StateProviders>
                                </NetworkStatusProvider>
                            </QueryClientProvider>
                        </MDXProvider>
                    </>
                </ThemeProvider>
            </MUIThemeProvider>
        </SimpleReactLightbox>
    );
};
