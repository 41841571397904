import React, { FC } from 'react';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import { SkillTabsPropsType } from './type';
import { SkillTabsRoot } from './style';
import { useSkillTabs } from './hooks/useSkillTabs';

const a11yProps = (index: number) => {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
};

export const SkillTabs: FC<SkillTabsPropsType> = (props) => {
    const { rootProps } = useSkillTabs(props);

    const [value, setValue] = React.useState(0);

    const handleChange = (_event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    return (
        <SkillTabsRoot {...rootProps}>
            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    aria-label="basic tabs example"
                >
                    <Tab
                        label="Tech skills"
                        disableRipple={true}
                        {...a11yProps(0)}
                    />
                    <Tab
                        label="Soft skills"
                        disableRipple={true}
                        {...a11yProps(1)}
                    />
                </Tabs>
            </Box>
        </SkillTabsRoot>
    );
};

SkillTabs.defaultProps = {};
