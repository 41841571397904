import React from 'react';
import { LEDSegmentRoot } from './style';

export const LEDSegment = () => {
    return (
        <LEDSegmentRoot>
            <svg
                width="47.727486mm"
                height="65.346939mm"
                viewBox="0 0 47.727486 65.346939"
                version="1.1"
                id="svg5"
                xmlns="http://www.w3.org/2000/svg"
            >
                <defs id="defs2" />
                <g id="layer1" transform="translate(-78.360651,-78.869642)">
                    <rect
                        id="rect214"
                        width="2.9744215"
                        height="19.552221"
                        x="100.90969"
                        y="76.172264"
                        transform="rotate(7.7862968)"
                    />
                    <rect
                        id="rect214-9"
                        width="2.9744215"
                        height="19.552221"
                        x="123.73714"
                        y="73.46981"
                        transform="rotate(7.7862968)"
                    />
                    <rect
                        id="rect214-7"
                        width="2.9744215"
                        height="19.552221"
                        x="85.862495"
                        y="-111.99078"
                        transform="rotate(89.920522)"
                    />
                    <rect
                        id="rect214-7-9"
                        width="2.9744215"
                        height="19.552221"
                        x="110.21944"
                        y="-109.50982"
                        transform="rotate(89.920522)"
                    />
                    <rect
                        id="rect214-91"
                        width="2.9744215"
                        height="19.552221"
                        x="101.18934"
                        y="100.9528"
                        transform="rotate(7.7862968)"
                    />
                    <rect
                        id="rect214-9-7"
                        width="2.9744215"
                        height="19.552221"
                        x="124.01678"
                        y="98.250343"
                        transform="rotate(7.7862968)"
                    />
                    <rect
                        id="rect214-7-9-2"
                        width="2.9744215"
                        height="19.552221"
                        x="134.80508"
                        y="-106.39555"
                        transform="rotate(89.920522)"
                    />
                    <rect
                        id="rect667"
                        width="5.073535"
                        height="4.6507406"
                        x="113.09756"
                        y="133.0746"
                    />
                    <text
                        xmlSpace="preserve"
                        x="100.20232"
                        y="82.973442"
                        id="text723"
                    >
                        <tspan id="tspan721" x="100.20232" y="82.973442">
                            A
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="117.72163"
                        y="100.77444"
                        id="text723-2"
                    >
                        <tspan id="tspan721-6" x="117.72163" y="100.77444">
                            B
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="114.38116"
                        y="125.93072"
                        id="text723-2-4"
                    >
                        <tspan id="tspan721-6-1" x="114.38116" y="125.93072">
                            C
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="98.314972"
                        y="107.64486"
                        id="text723-2-3"
                    >
                        <tspan id="tspan721-6-7" x="98.314972" y="107.64486">
                            G
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="81.086082"
                        y="100.35164"
                        id="text723-2-8"
                    >
                        <tspan id="tspan721-6-8" x="81.086082" y="100.35164">
                            F
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="77.809433"
                        y="124.97943"
                        id="text723-2-38"
                    >
                        <tspan id="tspan721-6-15" x="77.809433" y="124.97943">
                            E
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="95.144012"
                        y="144.21658"
                        id="text723-2-35"
                    >
                        <tspan id="tspan721-6-4" x="95.144012" y="144.21658">
                            D
                        </tspan>
                    </text>
                    <text
                        xmlSpace="preserve"
                        x="118.50341"
                        y="142.31401"
                        id="text723-2-36"
                    >
                        <tspan id="tspan721-6-5" x="118.50341" y="142.31401">
                            DP
                        </tspan>
                    </text>
                </g>
            </svg>
        </LEDSegmentRoot>
    );
};
