import styled from 'styled-components';
import {marginProps, muiColor, muiSpacing, muiTypography, reset,} from '@gannochenko/ui.styled-components';
import {propsBlocker} from '../../util';

import {SkillsV2RootPropsType, SkillsV2SkillLogoPropsType} from './type';
import {backgroundCover} from '@gannochenko/etc';

export const SkillsV2Root = styled.div.withConfig(
    propsBlocker,
)<SkillsV2RootPropsType>`
    ${reset};
    ${marginProps};
    display: flex;
    flex-wrap: wrap;
`;

export const SkillsV2Skill = styled.div`
    padding: ${muiSpacing(1)};
    margin: 0 ${muiSpacing(2)} ${muiSpacing(2)} 0;
    border: 2px solid ${muiColor('primary.main')};
    ${muiTypography('label')};
    border-radius: 999999px;
    display: flex;
    flex: 0 0 auto;
    align-items: center;
`;

export const SkillsV2SkillLabel = styled.div`
    padding: 0 ${muiSpacing(3)} 0 ${muiSpacing(2)};
`;

export const SkillsV2SkillLogo = styled.div`
    border-radius: 999999px;
    border: 1px solid ${muiColor('grey.400')};
    padding: ${muiSpacing(2)};
    overflow: hidden;
    min-width: 2.4rem;
    display: flex;
    justify-content: center;
`;

export const SkillsV2SkillLogoImage = styled.div<SkillsV2SkillLogoPropsType>`
    ${({ image }) => backgroundCover(image)};
    width: ${({ width }) => (width !== undefined ? width : muiSpacing(5))};
    height: ${muiSpacing(5)};
`;
