import React, { FC } from 'react';
import {
    AnalyticsLink,
    AT,
    CICDLink,
    END,
    FooterContacts,
    FooterLinks,
    FooterRoot,
    Links,
    NoWrap,
    Path,
    SlashContainer,
} from './style';
import { Social } from '../Social';
import { links } from '../../meta/links';
import { Slash } from '../Slash';
import { Link } from '../Link';
import { COOKIE_POLICY, PERSONAL_DATA_POLICY } from '../../pathTemplates';
import { useFooter } from './hooks/useFooter';

export const Footer: FC = () => {
    const {
        showDEV,
        setShowDEV,
        showArch,
        setShowArch,
        showAT,

        deploymentLink,
        gALink,
        showDeploymentLink,
        showGALinkLink,
    } = useFooter();

    return (
        <FooterRoot>
            <FooterContacts>
                <Links>
                    <Social type="github" src={links.github} />
                    <Social type="linkedin" src={links.linkedin} />
                    {/*<Social*/}
                    {/*    type="twitter"*/}
                    {/*    src={links.twitter}*/}
                    {/*    onMouseOver={() => {*/}
                    {/*        setShowDEV(true);*/}
                    {/*    }}*/}
                    {/*    onMouseOut={() => {*/}
                    {/*        setShowDEV(false);*/}
                    {/*    }}*/}
                    {/*/>*/}
                    <Social
                        type="telegram"
                        src={links.telegram}
                        onMouseOver={() => {
                            setShowDEV(true);
                        }}
                        onMouseOut={() => {
                            setShowDEV(false);
                        }}
                    />
                    <Social
                        type="instagram"
                        src={links.instagram}
                        onMouseOver={() => {
                            setShowArch(true);
                        }}
                        onMouseOut={() => {
                            setShowArch(false);
                        }}
                    />
                    <Social type="devto" src={links.devto} />
                </Links>
                <SlashContainer>
                    <Slash />
                </SlashContainer>
                <Path>
                    <AT show={showAT}>@</AT>gannochenko
                    <END show={showDEV || showArch}>
                        {showArch && '_arch'}
                        {showDEV && '_dev'}
                    </END>
                </Path>
            </FooterContacts>
            <FooterLinks>
                <NoWrap>
                    &copy; then (2017) &mdash; now ({new Date().getFullYear()})
                </NoWrap>
                <Link to={COOKIE_POLICY} bright>
                    Cookie policy
                </Link>
                <Link to={PERSONAL_DATA_POLICY} bright>
                    Personal data policy
                </Link>
            </FooterLinks>
            {showDeploymentLink && (
                <CICDLink
                    href={deploymentLink}
                    rel="noreferrer noopener nofollow"
                    target="_blank"
                />
            )}
            {showGALinkLink && (
                <AnalyticsLink
                    href={gALink}
                    rel="noreferrer noopener nofollow"
                    target="_blank"
                />
            )}
        </FooterRoot>
    );
};
