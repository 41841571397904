import { SkillsV2PropsType } from '../type';
import { useMemo } from 'react';
import { skills } from '../../../skills/skills';

export const useSkillsV2 = ({ list, ...props }: SkillsV2PropsType) => {
    const selected = useMemo(
        () =>
            list
                ? list
                      .split(',')
                      .map((item) => item.trim())
                      .filter((item) => !!item)
                      .map((item) => skills[item])
                : [],
        [list],
    );

    return {
        rootProps: {
            ...props, // rest props go to the root node, as before
        },
        skills: selected,
    };
};
