export function isHoHoHoTime() {
    const now = new Date();
    const month = now.getMonth() + 1;
    const day = now.getDate();

    return (month === 12 && (day > 1 && day < 31)) || (month === 1 && (day > 1 && day < 3));
}

export function makeSlug(text: string) {
    return text.toLowerCase().trim().replace(/[^a-z0-9\s-]/g, '').replace(/\s+/g, '-');
}

export function limitString(input: string, maxLength: number) {
    if (input.length <= maxLength) return input;

    // Find the last space within the limit
    const trimmed = input.slice(0, maxLength + 1); // +1 to include a potential partial word
    const lastSpaceIndex = trimmed.lastIndexOf(' ');

    // If no space is found, return the truncated string
    return lastSpaceIndex > -1 ? trimmed.slice(0, lastSpaceIndex) : trimmed.trim();
}
