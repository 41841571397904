import styled from 'styled-components';
import { round } from '@gannochenko/etc';
import { GatsbyImage } from 'gatsby-plugin-image';
import hat from './assets/hat.png';

export const OuterContainer = styled.div`
    position: relative;    
`;

export const Container = styled.div<{ size: string; borderSize: string }>`
    ${round()};
    width: ${(props) => props.size};
    flex-shrink: 0;
    border: ${(props) => props.borderSize} solid white;
    overflow: hidden;
    user-select: none;
    position: relative;
`;

export const Image = styled(GatsbyImage)`
    height: 100%;
    width: 100%;
    ${round()};
`;

export const Hat = styled.div`
    background-image: url(${hat});
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    position: absolute;
    top: -34px;
    left: 23px;
    transform: rotate(-30deg);
`;
