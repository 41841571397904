import styled, { css } from 'styled-components';
import { align, gap } from '@gannochenko/etc';
import { Grid } from '@material-ui/core';
import { withEffects } from '@gannochenko/ui';
import { muiSpacing } from '@gannochenko/ui.styled-components';

export const AuthorRoot = styled.div`
    ${align('center', 'left')};
    color: #fff;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            flex-direction: column;
        }
    `};
`;

export const AuthorLeft = withEffects(styled.div`
    ${align('top', 'center')};

    // @ts-ignore
    ${({ runStandardEffect, enableEffects }) =>
        enableEffects ? runStandardEffect() : ''};
`);

export const AuthorRight = styled(Grid).attrs({ item: true })`
    margin-left: 2rem;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            ${align('top', 'center', 'column')};
            margin-top: ${muiSpacing(5)};
            margin-left: 0;
        }
    `};
`;

export const AuthorNameBlock = withEffects(styled.h1`
    font-family: ${({ theme }) => theme.typography.emphasis.fontFamily};
    font-weight: 600;
    margin: 0 0 0.5rem 0;
    font-size: ${(props) => props.theme.typography.scream.fontSize};
    letter-spacing: 0.05rem;
    text-transform: uppercase;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            text-align: center;
        }
    `};

    // @ts-ignore
    ${({ runStandardEffect, enableEffects }) =>
        enableEffects ? runStandardEffect() : ''};
`);

export const AuthorGreetingBlock = withEffects(styled.div`
    letter-spacing: 0.05rem;
    line-height: 1.4;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            text-align: center;
        }
    `};

    // @ts-ignore
    ${({ runStandardEffect, enableEffects }) =>
        enableEffects ? runStandardEffect() : ''};
`);

export const AuthorLinks = withEffects(styled.div`
    display: flex;
    flex-shrink: 0;
    margin-top: 2rem;
    ${gap(null, '1.5rem')};

    // @ts-ignore
    ${({ runStandardEffect, enableEffects }) =>
        enableEffects ? runStandardEffect() : ''};
`);

export const Banner = styled.span`
    position: absolute;
    bottom: -1.5rem;
    right: 1rem;
    font-size: ${(props) => props.theme.typography.shout.fontSize};
    &:before {
        content: '🇺🇦';
    }
`;

export const BannerDove = styled.span`
    position: absolute;

    bottom: 0;
    right: -0.3rem;
    font-size: ${(props) => props.theme.typography.shout.fontSize};
    &:before {
        content: '🕊️';
    }
`;
