import React, {FunctionComponent, useEffect, useState} from 'react';
import {Container, Hat, Image, OuterContainer} from './style';
import { Query } from './query';

import { AvatarPropTypes } from './type';
import {isHoHoHoTime} from "../../util/etc";

export const Avatar: FunctionComponent<AvatarPropTypes> = ({
    size = '12rem',
    borderSize = '0.2rem',
    real,
}) => {
    const [hoHoHoTime, setHoHoHoTime] = useState<boolean>(false);
    useEffect(() => {
        // delay this, so it will not be SSR-ed
        setTimeout(() => {
            setHoHoHoTime(isHoHoHoTime());
        }, 300);
    }, []);

    return (
        <Query>
            {(data) => (
                <OuterContainer>
                    <Container size={size} borderSize={borderSize}>
                        <Image
                            image={
                                data[real ? 'avatar' : 'avatar_kun'].childImageSharp
                                    .gatsbyImageData
                            }
                            alt="Avatar"
                        />

                    </Container>
                    {
                        hoHoHoTime
                        &&
                        <Hat />
                    }
                </OuterContainer>
            )}
        </Query>
    );
};
