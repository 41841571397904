import styled, { css } from 'styled-components';
import {
    marginProps,
    muiSpacing,
    reset,
} from '@gannochenko/ui.styled-components';
import { propsBlocker } from '../../util';

import { SkillBlockRootPropsType } from './type';
import { Grid } from '@material-ui/core';

export const SkillBlockRoot = styled.div.withConfig(
    propsBlocker,
)<SkillBlockRootPropsType>`
    ${reset};
    ${marginProps};
`;

export const SkillBlockGrid = styled(Grid)`
    ${({ theme, flip }) => css`
        ${theme.breakpoints.down('sm')} {
            ${flip
                ? css`
                      flex-direction: column-reverse;
                  `
                : ''};
        }
    `};
`;

export const SkillBlockGridItem = styled(Grid)`
    display: flex;
    flex-direction: column;
    padding-right: ${muiSpacing(2)};
`;
