import {PropsWithChildren, useState} from "react";

import {CodeContainerPropsType} from "../type";

export const useCodeContainer = ({ bgColor, wide, expandable, file, blockKey, codeKeyColor, children }: PropsWithChildren<CodeContainerPropsType>) => {
    const [collapsed, setCollapsed] = useState(true);
    return {
        codeContainerProps: {
            collapsed: expandable && collapsed,
            bgColor,
            wide,
        },
        wrapperProps: {
            bgColor,
            roundedTop: !file,
        },
        codeKeyProps: {
            color: codeKeyColor,
        },
        expandButtonProps: {
            onClick: () => {
                setCollapsed(false);
            },
        },
        file,
        blockKey,
        children,
        showBlockKey: !!blockKey,
        showFile: !!file,
        showExpandButton: expandable && collapsed,
    };
};
