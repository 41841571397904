import React from 'react';

import { SearchBarRoot, SearchInput } from "./style";
import {Container} from "../Container";
import {useSearchBar} from "./hooks/useSearchBar";
import {SearchBarProps} from "./type";

export function SearchBar(props: SearchBarProps) {
    const {searchInputProps} = useSearchBar(props);

    return (
        <SearchBarRoot>
            <Container>
                <SearchInput placeholder="Search..." {...searchInputProps} />
            </Container>
        </SearchBarRoot>
    );
}
