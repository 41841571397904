module.exports.palette = {
    primary: {
        main: '#ce4f4f',
        light: '#ff807b',
        dark: '#981b27',
        contrastText: '#fff',
    },
    secondary: {
        main: '#880e4f',
        light: '#bc477b',
        dark: '#560027',
        contrastText: '#fff',
    },
    warning: {
        main: '#ffff67',
    },
    text: {
        primary: '#333',
        secondary: '#8c8b8b',
        inverted: '#ffffff',
    },
    background: {
        dark: '#20232a',
        gray: '#f3f3f3',
        default: '#fafafa',
        hz: '#eeeeee',
    },
};
