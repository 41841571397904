import { ForwardedRef } from 'react';
import { SkillTabsPropsType } from '../type';


export const useSkillTabs = <E extends HTMLDivElement>(

    { propA, ...props }: SkillTabsPropsType
) => {


    return {
        rootProps: {
            ...props, // rest props go to the root node, as before

        },
    };
};
