import styled, { css } from 'styled-components';

export const CodeContainerRoot = styled.div`
    margin: 2rem 0;
    position: relative;
`;

export const CodeContainerContainer = styled.div<{
    bgColor?: string;
    wide?: boolean;
    collapsed?: boolean;
}>`
    background-color: ${({ bgColor, wide }) =>
        wide ? bgColor : 'transparent'};

    line-height: 1.4rem;
    position: relative;
    min-height: 3rem;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            font-size: 1.1rem;
        }
    `};

    .token-line:last-child {
        display: none;
    }

    ${({ collapsed }) =>
        collapsed
            ? css`
                  overflow: hidden;
                  height: 20rem;
              `
            : ''};
`;

export const CodeKey = styled.div`
    position: absolute;
    top: 16px;
    left: 0.5rem;
    font-size: 1rem;
    color: ${(props) => props.color || 'inherit'};
    user-select: none;
`;

export const Wrapper = styled.div<{ bgColor: string; roundedTop?: boolean }>`
    background-color: ${(props) => props.bgColor};
    margin: 0 -1.5rem;
    padding: 0 1.5rem;
    border-radius: ${({ roundedTop }) => (roundedTop ? '5px' : '0 0 5px 5px')};
    overflow-x: auto;
    position: relative;
`;

export const File = styled.div`
    background-color: beige;
    padding: 0.2rem 0.5rem;
    margin: 0 -1.5rem;
    border-radius: 5px 5px 0 0;
    font-size: 0.8rem;
`;

export const ExpandButton = styled.button`
    position: absolute;
    bottom: 1.1rem;
    left: 0;
    right: 0;
    display: flex;
    justify-content: center;
    padding: 0.7rem;
    appearance: none;
    border: 0 none;
    cursor: pointer;
    background: rgba(219, 219, 217, 0.7);
    font-size: ${({ theme }) => theme.typography.smaller.fontSize};

    &:hover {
        background: rgba(219, 219, 217, 1);
    }
    transition: background-color 300ms ease;
`;

export const License = styled.div`
    padding: 0.2rem 0.5rem;
    margin: 0 -1.5rem;
    font-size: 0.5rem;
    user-select: none;
    text-align: right;

    color: #626161;

    & > * {
        color: inherit;
    }
`;
