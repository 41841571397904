import styled, { css } from 'styled-components';
import { align } from '@gannochenko/etc';
import { GatsbyImage } from 'gatsby-plugin-image';
import { muiSpacing } from '@gannochenko/ui.styled-components';

export const HeaderImageRoot = styled.div`
    ${align('center', 'center')};
`;

export const HeaderImageCover = styled(GatsbyImage)`
    height: 80vh;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            height: ${muiSpacing(100)};
        }
    `};
    user-select: none;
`;
