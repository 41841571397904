import { ChangeEventHandler, MutableRefObject, useEffect, useRef } from 'react';
import { SearchBarProps } from '../type';

const findInput = (ref: MutableRefObject<HTMLDivElement | undefined>) => {
    return ref.current?.querySelector('input');
};

function isMobile() {
    return /Mobi|Android/i.test(navigator.userAgent);
}

export const useSearchBar = ({
    query,
    onQueryChange,
    ...props
}: SearchBarProps) => {
    const searchInputRef = useRef<HTMLDivElement>();

    const focusTimeout = useRef<NodeJS.Timeout>();
    useEffect(() => {
        focusTimeout.current = setTimeout(() => {
            if (isMobile()) {
                return;
            }

            const input = findInput(searchInputRef);
            if (input) {
                input.focus();
            }
        }, 300);
        return () => {
            // @ts-expect-error
            clearTimeout(focusTimeout.current);
        };
    }, []);

    useEffect(() => {
        const handleKeyPress = (event: KeyboardEvent) => {
            if (event.key === 'Escape') {
                onQueryChange?.('');
                const input = findInput(searchInputRef);
                if (input) {
                    input.focus();
                }
            }
        };

        document.addEventListener('keydown', handleKeyPress);
        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);

    const onSearchInputChange: ChangeEventHandler<HTMLInputElement> = (
        event,
    ) => {
        onQueryChange?.(event.target.value);
    };

    return {
        rootProps: {
            ...props, // rest props go to the root node, as before
        },
        searchInputProps: {
            ref: searchInputRef,
            value: query,
            onChange: onSearchInputChange,
        },
        showSearchButton: query !== '',
        clearButtonProps: {
            onClick: () => {
                onQueryChange?.('');
                const input = findInput(searchInputRef);
                if (input) {
                    input.focus();
                }
            },
        },
    };
};
