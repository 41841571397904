import React, { FC } from 'react';

import { SkillBlockPropsType } from './type';
import { SkillBlockRoot, SkillBlockGrid, SkillBlockGridItem } from './style';
import { useSkillBlock } from './hooks/useSkillBlock';

export const SkillBlock: FC<SkillBlockPropsType> = (props) => {
    const { rootProps, gridProps, left, right } = useSkillBlock(props);

    return (
        <SkillBlockRoot {...rootProps}>
            <SkillBlockGrid {...gridProps}>
                <SkillBlockGridItem xs={12} md={6}>
                    {left}
                </SkillBlockGridItem>
                <SkillBlockGridItem xs={12} md={6}>
                    {right}
                </SkillBlockGridItem>
            </SkillBlockGrid>
        </SkillBlockRoot>
    );
};

SkillBlock.defaultProps = {};
