import React, { FunctionComponent } from 'react';
import { graphql, StaticQuery } from 'gatsby';
import { QueryProps } from '../../type';

// https://www.gatsbyjs.com/docs/reference/built-in-components/gatsby-plugin-image/
const query = graphql`
    query Avatar {
        avatar_kun: file(relativePath: { eq: "avatar_kun.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    width: 200
                    placeholder: BLURRED
                )
            }
        }
        avatar_cartoonish: file(relativePath: { eq: "avatar_cartoonish.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    width: 200
                    placeholder: BLURRED
                )
            }
        }
        avatar: file(relativePath: { eq: "avatar.jpg" }) {
            childImageSharp {
                gatsbyImageData(
                    layout: CONSTRAINED
                    width: 200
                    placeholder: BLURRED
                )
            }
        }
    }
`;

export const Query: FunctionComponent<QueryProps> = ({ children }) => {
    return <StaticQuery query={query} render={(data) => children(data)} />;
};
