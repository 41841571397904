import { Container } from 'components';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import * as React from 'react';
export default {
  Container,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  React
};