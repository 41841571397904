export const formatDate = (date: string | null, showOnlyYear = false) => {
    if (!date) {
        return '';
    }

    const dateInstance = new Date(date);

    const month = (dateInstance.getMonth() + 1).toString().padStart(2, '0');
    const day = dateInstance.getDate().toString().padStart(2, '0');
    const year = dateInstance.getFullYear();

    if (showOnlyYear) {
        return year;
    }

    return `${day}.${month}.${year}`;
};

export const getYear = (date?: string) => {
    if (!date) {
        return '';
    }

    const dateInstance = new Date(date);

    return dateInstance.getFullYear();
};

export const lightBoxOptions = {
    buttons: {
        showDownloadButton: false,
        showAutoplayButton: false,
        showThumbnailsButton: false,
    },
};
