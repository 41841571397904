import { Ref } from 'react';
import { ImageGalleryPropsType } from '../type';

export const useImageGallery = (
    ref: Ref<unknown>,
    { images, galleryId, ...props }: ImageGalleryPropsType,
) => {
    let realImages = images;
    if (galleryId !== undefined && images) {
        realImages = images.filter((image) => image.galleryId === galleryId);
    }

    return {
        rootProps: {
            ...props, // rest props go to the root node, as before
            ref, // same for the ref
        },

        images: realImages,
    };
};
