import React, {PropsWithChildren} from 'react';
import {Typography} from "../Typography";
import {makeSlug} from "../../util/etc";
import {Anchor, AnchorContainer} from "./style";

type MDXHeaderProps = Partial<PropsWithChildren<{
    variant: string;
}>>;

export function MDXHeader({children, variant}: MDXHeaderProps) {
    const slug = typeof children === "string" ? makeSlug(children) : "";
    return (
        <Typography enableVerticalGutter variant={variant ?? ""} component={variant ?? ""}>
            {
                !!slug
                &&
                <AnchorContainer>
                    #{' '}
                    <Anchor name={slug} />
                </AnchorContainer>
            }
            {children}
        </Typography>
    );
}
