import styled from 'styled-components';

import {GatsbyImage} from "gatsby-plugin-image";
import {muiColor, muiSpacing} from "@gannochenko/ui.styled-components";
import {Typography} from "../Typography";

export const CardRoot = styled("div")`
  box-shadow: 0 6px 16px 0 rgba(0, 0, 0, 0.1);
  background-color: ${muiColor("background.default")};
  border-radius: 5px;
  overflow: hidden;
  margin: ${muiSpacing(5 / 2)};
  height: 100%;
`;

export const ImageWrapper = styled("a")`
  position: relative;
  display: block;
  cursor: pointer;
  height: 15rem;
`;

export const Image = styled(GatsbyImage)`
  object-fit: cover;
  object-position: center;
  position: absolute !important;
  width: 100%;
  height: 100%;
`;

export const Title = styled(Typography)`
    font-weight: 600;
    color: ${muiColor("primary.main")};
    margin: 0;
    text-decoration: none;
    padding: ${muiSpacing(3)};
  
    a {
      text-decoration: none !important;
    }
`;

export const Date = styled.div`
    font-size: ${({ theme }) => theme.typography.small.fontSize};
    padding: ${muiSpacing(2)} ${muiSpacing(3)} 0 ${muiSpacing(3)};
    flex-shrink: 0;
    margin-right: 1rem;
    margin-top: 0.3rem;
`;

export const Text = styled.div`
    font-size: ${({ theme }) => theme.typography.small.fontSize};
    padding: ${muiSpacing(2)} ${muiSpacing(3)};
    flex-shrink: 0;
`;
