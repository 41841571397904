import React, { FunctionComponent } from 'react';

import { BlogCardPropsType } from './type';
import { BLOG_DETAIL, fillTemplate } from '../../../pathTemplates';
import {Card} from "../../Card";

export const BlogCard: FunctionComponent<BlogCardPropsType> = ({ data }) => {
    const {
        frontmatter: { slug, description, date, title, published, images },
    } = data;
    
    const path = fillTemplate(BLOG_DETAIL, { slug });
    const realPath = published
        ? path
        : path.replace(/^\/blog\//, '/blog-drafts/');

    return (<Card title={title} link={realPath} image={images?.[0].image ?? null} date={date} text={description} />)
};
