import { AuthorPropsType } from '../type';
import { StandardEffects } from '@gannochenko/ui';

export const useAuthor = ({
    enableSocialLinks,
    effectTimeout,
    enableEffects,
    showUkraineBanner,
}: AuthorPropsType) => {
    const timeoutName = (effectTimeout !== undefined ? effectTimeout : 0) + 200;

    return {
        showSocialLinks: !!enableSocialLinks,
        avatarContainerProps: {
            effectName: 'fade-enter' as StandardEffects,
            effectTimeout: timeoutName,
            effectEaseA: 'preset:bounce',
            enableEffects,
        },
        nameBlockProps: {
            effectName: 'fade-slide-bottom' as StandardEffects,
            effectTimeout: timeoutName,
            effectEaseA: 'preset:bounce',
            enableEffects,
        },
        greetingsBlockProps: {
            effectName: 'fade-slide-bottom' as StandardEffects,
            effectTimeout: timeoutName + 300,
            effectEaseA: 'preset:bounce',
            enableEffects,
        },
        socialLinksProps: {
            effectName: 'fade-slide-bottom' as StandardEffects,
            effectTimeout: timeoutName + 600,
            effectEaseA: 'preset:bounce',
            enableEffects,
        },
        showUkraineBanner: showUkraineBanner !== false,
    };
};
