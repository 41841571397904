import React from 'react';
import {Link} from "../Link";

type Item = {
	text: string;
	url?: string;
};

type ItemListProps = Partial<{
	items: Item[];
	markerEmoji: string;
}>;

export function ItemList({items, markerEmoji}: ItemListProps) {
	if(items?.length === 0) {
		return null;
	}

	const emoji = markerEmoji ?? '✅';

	return (
		<div>
			{
				items?.map(item => {
					if (item.url) {
						return (<div key={item.text}>{emoji} <Link href={item.url}>{item.text}</Link></div>);
					}

					return (<div key={item.text}>{emoji} {item.text}</div>);
				})
			}
		</div>
	);
}
