import styled from 'styled-components';

export const TM1637CircuitRoot = styled.div`
    //line {
    //    stroke: rgb(0, 0, 0);
    //    stroke-linecap: square;
    //    stroke-width: 2;
    //}
    //text {
    //    font-family: Arial, sans-serif;
    //    font-size: 12px;
    //    text-anchor: end;
    //}
`;

export const Line = styled.line`
    stroke: rgb(0, 0, 0);
    stroke-linecap: square;
    stroke-width: 2;
`;

export const RailPath = styled.path`
    fill-opacity: 0;
    fill: rgb(0, 0, 0);
    stroke: rgb(0, 0, 0);
    stroke-linecap: square;
    stroke-width: 2;
`;

export const RailText = styled.text`
    font-family: Arial;
    font-size: 12px;
    text-anchor: end;
`;

export const ICText = styled.text`
    font-family: Arial;
    font-size: 11px;
    text-anchor: middle;
`;

export const PinText = styled.text`
    font-family: Arial;
    font-size: 11px;
    text-anchor: start;
`;

export const WireDot = styled.ellipse`
    fill-opacity: 1;
    fill: rgb(0, 0, 0);
    stroke: rgb(0, 0, 0);
    stroke-width: 2;
`;

export const IC = styled.rect`
    fill-opacity: 0;
    fill: rgb(0, 0, 0);
    stroke: rgb(0, 0, 0);
    stroke-width: 2;
`;
