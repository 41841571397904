import styled from 'styled-components';
import { marginProps, reset } from '@gannochenko/ui.styled-components';
import { propsBlocker } from '../../util';

import { SkillTabsRootPropsType } from './type';

export const SkillTabsRoot = styled.div.withConfig(
    propsBlocker,
)<SkillTabsRootPropsType>`
    ${reset};
    ${marginProps};
`;
