import React, {FC} from 'react';

import {SkillsV2PropsType} from './type';
import {SkillsV2Root, SkillsV2Skill, SkillsV2SkillLabel, SkillsV2SkillLogo, SkillsV2SkillLogoImage,} from './style';
import {useSkillsV2} from './hooks/useSkillsV2';

export const SkillsV2: FC<SkillsV2PropsType> = (props) => {
    const { rootProps, skills } = useSkillsV2(props);

    return (
        <SkillsV2Root {...rootProps}>
            {skills.map((skill) => (
                <SkillsV2Skill key={skill.title}>
                    <SkillsV2SkillLogo>
                        <SkillsV2SkillLogoImage
                            image={skill.logo}
                            width={skill.widthV2}
                        />
                    </SkillsV2SkillLogo>
                    <SkillsV2SkillLabel>{skill.title}</SkillsV2SkillLabel>
                </SkillsV2Skill>
            ))}
        </SkillsV2Root>
    );
};

SkillsV2.defaultProps = {};
