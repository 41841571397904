import autodesk from './logo/3dsmax.svg';
import aftereffects from './logo/aftereffects.svg';
import angularjs from './logo/angularjs.svg';
import apollo from './logo/apollo.svg';
import arduino from './logo/arduino.svg';
import auth0 from './logo/auth0.svg';
import aws from './logo/aws.svg';
import bitrix from './logo/bitrix.svg';
import bitrix24 from './logo/bitrix24.svg';
import blender from './logo/blender.svg';
import cpp from './logo/cpp.svg';
import csharp from './logo/csharp.svg';
import css from './logo/css.svg';
import dart from './logo/dart.svg';
import docker from './logo/docker.svg';
import expressjs from './logo/expressjs.svg';
import flutter from './logo/flutter.svg';
import gatsbyjs from './logo/gatsbyjs.svg';
import git from './logo/git.svg';
import go from './logo/go.svg';
import googlecloud from './logo/googlecloud.svg';
import hg from './logo/hg.png';
import html from './logo/html.svg';
import jamstack from './logo/jamstack.svg';
import java from './logo/java.svg';
import jest from './logo/jest.svg';
import js from './logo/js.svg';
import kubernetes from './logo/k8s.svg';
import kotlin from './logo/kotlin.svg';
import less from './logo/less.svg';
import linux from './logo/linux.svg';
import meteorjs from './logo/meteorjs.svg';
import mobx from './logo/mobx.png';
import mongodb from './logo/mongodb.svg';
import mysql from './logo/mysql.svg';
import nestjs from './logo/nestjs.svg';
import netlify from './logo/netlify.svg';
import nextjs from './logo/nextjs.svg';
import nodejs from './logo/nodejs.svg';
import perl from './logo/perl.svg';
import photoshop from './logo/photoshop.svg';
import php from './logo/php.svg';
import postgressql from './logo/postgressql.svg';
import python from './logo/python.svg';
import raspberry from './logo/raspberry.svg';
import reactjs from './logo/reactjs.svg';
import reactquery from './logo/reactquery.svg';
import redis from './logo/redis.svg';
import redux from './logo/redux.svg';
import saga from './logo/saga.png';
import sass from './logo/sass.svg';
import serverless from './logo/serverless.svg';
import styledcomponents from './logo/styledcomponents.png';
import terraform from './logo/terraform.svg';
import typeorm from './logo/typeorm.png';
import typescript from './logo/typescript.svg';
import unrealengine from './logo/unrealengine.svg';
import vagrant from './logo/vagrant.svg';
import vercel from './logo/vercel.svg';
import vray from './logo/vray.svg';

export const logo = {
    apollo,
    aws,
    bitrix,
    css,
    docker,
    expressjs,
    gatsbyjs,
    git,
    html,
    js,
    less,
    linux,
    meteorjs,
    mongodb,
    mysql,
    nodejs,
    php,
    postgressql,
    reactjs,
    redis,
    redux,
    saga,
    sass,
    serverless,
    styledcomponents,
    terraform,
    typescript,
    kubernetes,
    nestjs,
    typeorm,
    vagrant,
    angularjs,
    perl,
    python,
    hg,
    csharp,
    mobx,
    auth0,
    netlify,
    vercel,
    kotlin,
    reactquery,
    autodesk,
    vray,
    jamstack,
    nextjs,
    aftereffects,
    jest,
    bitrix24,
    photoshop,
    blender,
    go,
    googlecloud,
    arduino,
    java,
    cpp,
    raspberry,
    flutter,
    dart,
    unrealengine,
};
