import styled from 'styled-components';

export const LEDSegmentRoot = styled.div`
    rect {
        fill: #000000;
        stroke-width: 0.263413;
    }
    text {
        font-size: 5.64444px;
        fill: #000000;
        stroke-width: 0.264583;
    }
    tspan {
        font-size: 5.64444px;
        stroke-width: 0.264583;
    }
`;
