import React from 'react';

import { HobbyCard } from './HobbyCard';
import { HobbiesListContainer, GridCell } from './style';
import { HobbiesListPropsType } from './type';
import Grid from "@material-ui/core/Grid";
import {HobbiesEntry} from "../../type";

export const HobbiesList = ({ data }: HobbiesListPropsType) => {
    if (!data) {
        return null;
    }

    return (
        <HobbiesListContainer>
            <Grid container spacing={5}>
                {data.map((itemData) => {
                    return (
                        <GridCell md={4} sm={6} xs={12} key={itemData.node.id} component="div">
                            <HobbyCard data={itemData.node.frontmatter as HobbiesEntry} />
                        </GridCell>
                    );
                })}
            </Grid>
        </HobbiesListContainer>
    );
};
