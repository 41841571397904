module.exports.site = {
    title: 'Gannochenko Sergei: full-stack software engineer in TypeScript / Go',
    logoText: '$ cd ~',
    description:
        'My name is Sergei, I am a full-stack software engineer in TypeScript / Go domain, open-source contributor, in ❤️ with tech.',
    keywords: [
        'Sergei Gannochenko',
        'software engineering',
        'typescript',
        'react',
        'node',
        'javascript',
        'aws',
        'gatsby',
        'jamstack',
        'docker',
        'blog',
        'it',
        'tech',
        'go',
        'golang',
    ],
    author: '@gannochenko',
    baseURL: 'https://gannochenko.dev',
};
