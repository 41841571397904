import { useCallback, useEffect, useState } from 'react';
import copy from 'copy-to-clipboard';
import {
    defaultProps as highlightDefaultProps,
    Prism,
} from 'prism-react-renderer';

import { listingBashTheme, listingDefaultTheme } from '../prismTheme';
import { ListingPropsType } from '../type';

export const useListing = ({ children }: ListingPropsType) => {
    const [copied, setCopied] = useState(false);
    const { props } = children;

    const className = props.className || '';
    const linesCount = (props.children || '').split('\n').length;

    const matches = className.match(/language-(?<lang>.*)/);
    const { groups: { lang = 'js' } = {} } = matches || {};

    const theme = lang === 'bash' ? listingBashTheme : listingDefaultTheme;
    const wide = lang === 'bash' || linesCount > 30;
    const keyColor = theme.styles[3].style.color;
    const { bashRoot, metastring } = props;

    let blockKey = '';
    if (lang === 'bash') {
        blockKey = '$';
        if (bashRoot) {
            blockKey = '#';
        }
    }

    let file = '';
    if (metastring) {
        const match = metastring.match(/file:([^\s]+)/);
        if (match && match[1]) {
            file = match[1];
        }
    }

    const [, setHLLoaded] = useState(false);
    useEffect(() => {
        // @ts-expect-error
        (typeof global !== 'undefined' ? global : window).Prism = Prism;
        // @ts-expect-error
        import('prismjs/components/prism-dart')
            .then(() => import('prismjs/components/prism-docker'))
            .then(() => import('prismjs/components/prism-makefile'))
            .then(() => {
                setHLLoaded(true); // trigger re-render
            });
    }, []);

    const onCopyClick = useCallback(() => {
        copy(props.children);
        setCopied(true);
    }, [setCopied, copy]);

    const isMermaid = lang === 'mermaid';

    return {
        codeContainerProps: {
            bgColor: theme.plain.backgroundColor!,
            wide,
            expandable: wide && linesCount > 30,
            codeKeyColor: keyColor!,
            blockKey,
            file,
        },
        copyButtonProps: {
            onClick: onCopyClick,
            copied,
        },
        highlightProps: {
            ...highlightDefaultProps,
            theme,
            code: props.children,
            language: lang,
        },
        showMermaid: isMermaid,
        showCode: !isMermaid,
        getMermaidProps: () => {
            return {
                chart: props.children,
            };
        },
    };
};
