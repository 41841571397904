import React, {ReactNode} from 'react';
import Box from "@mui/material/Box";

type ComplexityProps = {
	time: ReactNode;
	space: ReactNode;
};

export function Complexity({time, space}: ComplexityProps) {
	return (
		<Box>
			<b>Time</b>: {time}, <b>space</b>: {space}
		</Box>
	);
}
