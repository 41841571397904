import styled from 'styled-components';
import Grid from "@material-ui/core/Grid";
import {muiSpacing} from "@gannochenko/ui.styled-components";

export const BlogPostListContainer = styled.div`
    margin-bottom: 2rem;
`;

export const LinkContainer = styled.div`
    margin-top: 4rem;
`;

export const Posts = styled.div`
    & > *:not(:last-child) {
        margin-bottom: 2rem;
    }
`;

export const NothingFound = styled.div`
    color: ${(props) => props.theme.palette.text.secondary};
`;

export const GridCell = styled(Grid)`
    margin-bottom: ${muiSpacing(5)};
`;