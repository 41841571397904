import Fuse from 'fuse.js';
import { useEffect, useMemo, useState } from 'react';

export const useFuse = <D>(query: string, keys: string[], data: D[]) => {
    const [searchResult, setSearchResult] = useState<D[]>([]);
    const fuse = useMemo(() => new Fuse(data, {
        threshold: 0.4,
        keys,
    }), [data]);
    useEffect(() => {
        if (query !== '') {
            const res = fuse.search(query).map((item) => item.item);
            setSearchResult(res);
        }
    }, [query]);

    return {
        result: query ? searchResult : data,
    };
};
