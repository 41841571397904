import React, { FC } from 'react';
import { AboutAuthorBackground } from './style';
import { Author } from '../Author';

export const AboutAuthor: FC = () => {
    return (
        <AboutAuthorBackground>
            <Author showUkraineBanner={false} />
        </AboutAuthorBackground>
    );
};
