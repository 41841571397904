/* eslint-disable react/display-name */

import React, {ComponentType} from 'react';
import { ObjectLiteralType } from '@gannochenko/ui.styled-components';
import styled from 'styled-components';

import { Typography } from '../Typography';
import { Link } from '../Link';
import { HR } from '../HR';
import { InlineCode } from '../InlineCode';
import { Listing } from '../Listing';
import {Container} from "../Container";
import {MDXHeader} from "../MDXHeader/MDXHeader";

const wrap = (Child: ComponentType) => (props: ObjectLiteralType) => <Container><Child {...props} /></Container>;

const Paragraph = (props: ObjectLiteralType) => (
    <Typography
        {...props}
        variant="body1"
        component="p"
        enableVerticalGutter
    />
);

const Header1 = ({children}: ObjectLiteralType) => (
    <MDXHeader
        variant="h1"
    >
        {children}
    </MDXHeader>
);

const Header2 = ({children}: ObjectLiteralType) => (
    <MDXHeader
        variant="h2"
    >
        {children}
    </MDXHeader>
);

const Header3 = ({children}: ObjectLiteralType) => (
    <MDXHeader
        variant="h3"
    >
        {children}
    </MDXHeader>
);

const Header4 = ({children}: ObjectLiteralType) => (
    <MDXHeader
        variant="h4"
    >
        {children}
    </MDXHeader>
);

const QuoteTypography = styled('div')`
    font-style: italic;
    border-left: 5px solid #b7b7b7;
`;

const Quote = (props: ObjectLiteralType) => (<QuoteTypography {...props} />);

const Separator = () => <HR />;

export const MDXComponents = {
    a: Link,
    p: Paragraph,
    h1: Header1,
    h2: Header2,
    h3: Header3,
    h4: Header4,
    hr: Separator,
    pre: Listing,
    inlineCode: InlineCode,
    blockquote: Quote,
};

export const WrappedMDXComponents = {
    a: Link,
    p: wrap(Paragraph),
    h1: wrap(Header1),
    h2: wrap(Header2),
    h3: wrap(Header3),
    h4: wrap(Header4),
    hr: Separator,
    pre: Listing,
    inlineCode: InlineCode,
    blockquote: wrap(Quote),
};
