import {BlogListPropsType, NodeType} from '../type';
import {useState} from "react";
import {useFuse} from "../../../hooks/useFuse";

export const useBlogList = (
    { data, showLink, drafts, showSearchBar, ...restProps }: BlogListPropsType,
) => {
    const [query, setQuery] = useState("");
    const { result } = useFuse<NodeType>(query, ['node.frontmatter.title', 'node.frontmatter.description'], data ?? []);

    return {
        rootProps: restProps,
        data: result,
        searchBarProps: {
            query,
            onQueryChange: setQuery,
        },
        showLink,
        drafts,
        nothingFound: result.length === 0,
        somethingFound: result.length !== 0,
        showSearchBar,
    };
};
