import styled from 'styled-components';
import {InputBase} from "@mui/material";
import {muiColor} from "@gannochenko/ui.styled-components";

export const SearchBarRoot = styled.div`
    margin-bottom: 2rem;
    background-color: ${muiColor("background.hz")};
    padding: 1rem 0;
`;

export const SearchInput = styled(InputBase)`
    font-size: 1.2rem !important;
    width: 100%;
`;
