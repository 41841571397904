import React from 'react';
import {
    IC,
    ICText,
    Line,
    PinText,
    RailPath,
    RailText,
    TM1637CircuitRoot,
    WireDot,
} from './style';

export const TM1637Circuit = () => {
    return (
        <TM1637CircuitRoot>
            <svg
                version="1.1"
                width="480"
                height="240"
                xmlns="http://www.w3.org/2000/svg"
            >
                <Line x1="340" y1="90" x2="340" y2="200" />
                <Line x1="310" y1="80" x2="310" y2="170" />
                <Line x1="310" y1="80" x2="340" y2="80" />
                <Line x1="180" y1="70" x2="180" y2="200" />
                <Line x1="120" y1="60" x2="120" y2="170" />
                <Line x1="120" y1="60" x2="180" y2="60" />
                <Line x1="70" y1="200" x2="450" y2="200" />
                <Line x1="70" y1="200" x2="70" y2="208" />
                <RailPath d="M 62,208 L 78,208 L 70,216 L 62,208" />
                <RailText
                    x="55"
                    y="170"
                    dominantBaseline="middle"
                    transform="rotate(0, 55, 170)"
                >
                    +5V
                </RailText>
                <Line x1="60" y1="170" x2="450" y2="170" />
                <Line x1="300" y1="70" x2="340" y2="70" />
                <Line x1="300" y1="60" x2="340" y2="60" />
                <ICText
                    x="240"
                    y="40"
                    dominantBaseline="middle"
                    transform="rotate(0, 240, 40)"
                >
                    Arduino Nano 33 IoT
                </ICText>
                <IC x="190" y="50" width="100" height="40" />
                <Line x1="180" y1="60" x2="190" y2="60" />
                <PinText
                    x="194"
                    y="60"
                    dominantBaseline="middle"
                    transform="rotate(0, 194, 60)"
                >
                    VCC
                </PinText>
                <Line x1="290" y1="60" x2="300" y2="60" />
                <PinText
                    x="260"
                    y="60"
                    dominantBaseline="middle"
                    transform="rotate(0, 286, 60)"
                >
                    PIN7
                </PinText>
                <Line x1="180" y1="70" x2="190" y2="70" />
                <PinText
                    x="194"
                    y="70"
                    dominantBaseline="middle"
                    transform="rotate(0, 194, 70)"
                >
                    GND
                </PinText>
                <Line x1="290" y1="70" x2="300" y2="70" />
                <PinText
                    x="260"
                    y="70"
                    dominantBaseline="middle"
                    transform="rotate(0, 286, 70)"
                >
                    PIN8
                </PinText>
                <ICText
                    x="400"
                    y="40"
                    dominantBaseline="middle"
                    transform="rotate(0, 400, 40)"
                >
                    TM1637
                </ICText>
                <IC x="350" y="50" width="100" height="50" />
                <Line x1="340" y1="60" x2="350" y2="60" />
                <PinText
                    x="354"
                    y="60"
                    dominantBaseline="middle"
                    transform="rotate(0, 354, 60)"
                >
                    CLK
                </PinText>
                <Line x1="340" y1="70" x2="350" y2="70" />
                <PinText
                    x="354"
                    y="70"
                    dominantBaseline="middle"
                    transform="rotate(0, 354, 70)"
                >
                    DAT
                </PinText>
                <Line x1="340" y1="80" x2="350" y2="80" />
                <PinText
                    x="354"
                    y="80"
                    dominantBaseline="middle"
                    transform="rotate(0, 354, 80)"
                >
                    VCC
                </PinText>
                <Line x1="340" y1="90" x2="350" y2="90" />
                <PinText
                    x="354"
                    y="90"
                    dominantBaseline="middle"
                    transform="rotate(0, 354, 90)"
                >
                    GND
                </PinText>
                <WireDot cx="340" cy="200" rx="2" ry="2" />
                <WireDot cx="310" cy="170" rx="2" ry="2" />
                <WireDot cx="180" cy="200" rx="2" ry="2" />
                <WireDot cx="120" cy="170" rx="2" ry="2" />
            </svg>
        </TM1637CircuitRoot>
    );
};
