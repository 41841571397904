import React, { FunctionComponent } from 'react';

import {
    Title,
    Image,
    CardRoot,
    ImageWrapper,
    Date,
    Text,
} from './style';
import { formatDate } from '../../lib/util';
import {GatsbyImageProps} from "gatsby-plugin-image";
import {Link} from "../Link";
import {limitString} from "../../util/etc";

export type CardPropsType = Partial<{
    link: string;
    title: string;
    text: string;
    date: string;
    image: {
        childImageSharp: GatsbyImageProps;
    } | null
    showOnlyYear: boolean;
}>;

export const Card: FunctionComponent<CardPropsType> = ({ link, title, image, date, text, showOnlyYear }) => {
    return (
        <CardRoot>
            {
                !!image
                &&
                <ImageWrapper
                    href={link ?? "/"}
                >
                    <Image
                        image={image!.childImageSharp.gatsbyImageData}
                        alt=""
                    />
                </ImageWrapper>
            }
            {
                !!date
                &&
                <Date>{formatDate(date, !!showOnlyYear)}</Date>
            }
            <Title variant="h4" component="div">
                <Link href={link ?? "/"}>
                    {title ?? "No title"}
                </Link>
            </Title>
            {
                !!text
                &&
                <Text>{limitString(text, 100)}...</Text>
            }
        </CardRoot>
    );
};
