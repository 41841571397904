import React from 'react';
import Highlight from 'prism-react-renderer';

import { Copy } from './style';
import { ListingPropsType } from './type';
import { CodeContainer } from './components/CodeContainer';
import { useListing } from './hooks/useListing';
import { Mermaid } from '../Mermaid';
import { Align } from '../Align';

export const Listing = ({ children }: ListingPropsType) => {
    const {
        codeContainerProps,
        copyButtonProps,
        highlightProps,
        showMermaid,
        getMermaidProps,
    } = useListing({ children });

    if (showMermaid) {
        return (
            <Align>
                <Mermaid {...getMermaidProps()} />
            </Align>
        );
    }

    return (
        <CodeContainer {...codeContainerProps}>
            <Copy {...copyButtonProps} />
            <Highlight {...highlightProps}>
                {({
                    className,
                    style,
                    tokens,
                    getLineProps,
                    getTokenProps,
                }) => (
                    <pre className={`${className} line-numbers`} style={style}>
                        {tokens.map((line, i) => (
                            <div {...getLineProps({ line, key: i })}>
                                {line.map((token, key) => (
                                    <span {...getTokenProps({ token, key })} />
                                ))}
                            </div>
                        ))}
                    </pre>
                )}
            </Highlight>
        </CodeContainer>
    );
};
