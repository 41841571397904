import React, {FC} from 'react';

import { BlogCard } from './BlogCard';
import { BlogPostListContainer, LinkContainer, Posts, NothingFound, GridCell } from './style';
import { BlogListPropsType } from './type';
import { Link } from '../Link';
import {SearchBar} from "../SearchBar";
import {Container} from "../Container";
import {useBlogList} from "./hooks/useBlogList";
import Grid from "@material-ui/core/Grid";

export const BlogList: FC<BlogListPropsType> = (props) => {
    const {data, searchBarProps, showLink, drafts, nothingFound, somethingFound, showSearchBar} = useBlogList(props);

    return (
        <BlogPostListContainer>
            {
                showSearchBar
                &&
                <SearchBar {...searchBarProps} />
            }
            <Container>
                {
                    nothingFound
                    &&
                    <NothingFound>Nothing was found...</NothingFound>
                }
                {
                    somethingFound
                    &&
                    <Posts>
                        <Grid container spacing={5}>
                            {data.map((itemData) => {
                                return (
                                    <GridCell md={4} sm={6} xs={12} key={itemData.node.id} component="div">
                                        <BlogCard data={itemData.node} />
                                    </GridCell>
                                );
                            })}
                        </Grid>
                    </Posts>
                }
                {!!showLink && (
                    <LinkContainer>
                        <Link to={drafts ? '/blog-drafts' : '/blog'}>
                            See all posts &rarr;
                        </Link>
                    </LinkContainer>
                )}
            </Container>
        </BlogPostListContainer>
    );
};
