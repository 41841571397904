import React, {PropsWithChildren} from 'react';
import Box from "@mui/material/Box";
import {Container} from "../Container";
export function HobbyContainer({children}: PropsWithChildren<any>) {
	return (
		<Box maxWidth={{md: 900}}>
			<Container>
				{children}
			</Container>
		</Box>
	);
}
