import React, { FunctionComponent } from 'react';

import facebookLogo from './assets/facebook.svg';
import twitterLogo from './assets/twitter.svg';
import mediumLogo from './assets/medium.svg';
import linkedinLogo from './assets/linkedin.svg';
import githubLogo from './assets/github.svg';
import instagramLogo from './assets/instagram.svg';
import devtoLogo from './assets/devto.svg';
import telegramLogo from './assets/telegram.svg';

import { Image } from './style';
import { SocialPropsType } from './type';

export const Social: FunctionComponent<SocialPropsType> = ({
    type,
    src,
    ...restProps
}) => {
    let logo = null;
    if (type === 'facebook') {
        logo = facebookLogo;
    } else if (type === 'twitter') {
        logo = twitterLogo;
    } else if (type === 'medium') {
        logo = mediumLogo;
    } else if (type === 'linkedin') {
        logo = linkedinLogo;
    } else if (type === 'github') {
        logo = githubLogo;
    } else if (type === 'instagram') {
        logo = instagramLogo;
    } else if (type === 'devto') {
        logo = devtoLogo;
    } else if (type === 'telegram') {
        logo = telegramLogo;
    }

    if (!logo) {
        return null;
    }

    if (src) {
        return (
            <a
                href={src}
                target="_blank"
                rel="noreferrer noopener"
                {...restProps}
            >
                <Image src={logo} />
            </a>
        );
    }

    return <Image src={logo} {...restProps} />;
};
